import {
    Box,
    Divider,
    Flex,
    Image,
    SimpleGrid,
    Stack,
    Text
} from '@chakra-ui/react'
import * as React from 'react'
import Debug from '../../Forms/Debug'
import { DiplomadQrCode } from '../Diploma/QrCode'
import * as dayjs from 'dayjs'
import { convertEctsGradeToEuGpa, ectsGrade, passingRank } from './rankUtils'
import { Italic, Strong } from '../../UI/Texts'
import stampPurple from '../../Images/stamps/stamps-purple.png'

let now = dayjs();
var lodash = require('lodash');


export const TranscriptFooter = ({ data, promoNotes, bg }) => {

    let allYearNotes = lodash.flatten(data.notes.map(item => item.values));

    const ectsCreditSum = () => {
        let allYearsPassingNotes = allYearNotes.filter(item => passingRank(item.note, data.program));
        let allCourseEctsSum = lodash.sum(allYearsPassingNotes.map(item => item.course.ectsCredits))
        return allCourseEctsSum;
    }

    const itemsPrimary = [
        ...(data.foreignLanguageTest.obtention && data.foreignLanguageTest.isExempted === false ?
            [
                `Foreign Language Test :
                ${data.foreignLanguageTest.type !== 'Other' ? data.foreignLanguageTest.type : data.foreignLanguageTest.description}${data.foreignLanguageTest.level ? `, level ${data.foreignLanguageTest.level}` : ``}${data.foreignLanguageTest.value ? `, score ${data.foreignLanguageTest.value}` : ``}
                on ${dayjs(data.foreignLanguageTest.date).format('YYYY/MM/DD')}`
            ]
            : data.foreignLanguageTest.obtention && data.foreignLanguageTest.isExempted === true ?
                [`Foreign Language Test : Exempted`]
                : []
        ),
    ]


    const euGpaAverage = () => {
        let yearEuGpaArray = []

        // console.log("data.notes", data.notes)
        let index = -1;

        // for (let index = 0; index < data.notes.length; index++) {
        for (let yearNote of data.notes) {
            index++;
            console.log('yearNote', yearNote)
            let year = yearNote.year;
            if (yearNote.values.length === 0) continue;
            // console.log('year', year);
            // continue;

            // console.log('notes before', data.notes)

            const notes =
                data.notes
                    .filter(item => item.year === year)[0]?.values
                    .filter(item => item.course?.passing?.system !== 'passOrFail' ? item : null)
                    .filter(item => item.note >= 0)

            console.log('notes after', notes)
            // console.log('promoNotes ', promoNotes)


            if (!notes) continue

            yearEuGpaArray[index] = []
            yearEuGpaArray[index].push(
                // lodash.mean(
                ...notes.map(item =>
                    convertEctsGradeToEuGpa(
                        ectsGrade(
                            item,
                            lodash.flatten(
                                lodash.flatten(promoNotes)
                                    .filter(item => item.year === year)
                                    // .filter(item => item.note > 0)
                                    .map(item => item.values)
                            ),
                            data.program
                        )
                    )
                )
                // )
            )

            // console.log(' yearEuGpaArray[index] #1', yearEuGpaArray[index])

            if (data.finalCredits.filter(item => item.year === year)) {
                yearEuGpaArray[index] = yearEuGpaArray[index].concat(
                    data.finalCredits.filter(item => item.year === year)
                        .map(item => convertEctsGradeToEuGpa(item.grade.toUpperCase()))
                )
            }

            // console.log(' yearEuGpaArray[index] #2', yearEuGpaArray[index])
        }


        console.log('yearEuGpaArray##', yearEuGpaArray)
        let yearFinalCreditsGrade = []
        for (let gpaNoteArray of yearEuGpaArray) {
            console.log('gpaNoteArray', gpaNoteArray)
            yearFinalCreditsGrade.push(lodash.mean(gpaNoteArray))
        }

        // console.log('yearFinalCreditsGrade', yearFinalCreditsGrade)
        // console.log('yearEuGpaArray###', yearEuGpaArray);

        let globalAvergageGpa = lodash.round(lodash.mean(yearFinalCreditsGrade), 2);


        return globalAvergageGpa
        // console.log('finalCreditsGrade', finalCreditsGrade)

        // let allEuGpaGrades = [...yearEuGpaArray, ...finalCreditsGrade].filter(item => item > 0)

        // return Math.ceil(lodash.mean(allEuGpaGrades) * 100) / 100
    }

    const itemsSecondary = [
        // `Award : ${data.program.localName}`,
        `Status: ${data.status} ${data.status === 'finalized' ? ` - no foreign language test` : ``}`,
        // `Major: ${data.program.major}`,
        // `Minor: ${data.coursePlan.minor}`,
        `Cumulated credits: ${data.ectsCreditsObtained} ECTS / ${Math.round(data.ectsCreditsObtained * 2 / 3)} US credits`,
        `EU GPA: ${euGpaAverage()}`,
    ]


    return (
        <>
            {/* <Debug data={data.notes.length} /> */}
            {/* <Debug data={euGpaAverage()} /> */}

            {/* <Debug data={
                // euGpaAverage()
                // lodash.flatten(data.notes.map(item => item.values))

                // lodash.mean(
                data.notes
                    .filter(item => item.year === 2)[0].values
                    .map(item =>
                        convertEctsGradeToEuGpa(
                            ectsGrade(item,
                                lodash.flatten(
                                    lodash.flatten(promoNotes)
                                        .filter(item => item.year === 1)
                                        .map(item => item.values)
                                )
                                , data.program
                            )
                        )
                    )
                // )
            } /> */}
            <Flex
                flex='1 1 auto'
                color={`brand.blue.900`}
                alignItems='flex-end'

            >
                <Box
                    w='full'
                >
                    <Box>
                        <Stack
                            spacing={`1mm`}
                            fontFamily='Satoshi'
                        >
                            {data.initialCredits ?
                                <Box fontSize={'10px'}>
                                    {data.initialCredits
                                        .filter(item => !item.isCourseRecognition)
                                        .map((item, index) =>
                                            <Flex
                                                key={`initialCreditsLine-${index}`}
                                            >
                                                {
                                                    (item.description && item.ectsCredits) ?
                                                        <Box>
                                                            Transferred credits from {item.description} conferring a credit equivalence of {item.ectsCredits} ECTS credits.
                                                        </Box>
                                                        :
                                                        item.description
                                                            ?
                                                            <Box>Previous studies : {item.description}</Box>
                                                            : null
                                                }
                                            </Flex>
                                        )}
                                </Box>
                                : null}

                            {itemsPrimary.length ?
                                <Divider borderColor={`brand.blue.900`} />
                                : null}

                            {itemsPrimary.map((item, index) =>
                                <Text
                                    fontWeight={'600'}
                                    fontSize='9px'
                                    key={`${index}-${item}`}
                                >
                                    {item}
                                </Text>
                            )}

                            <Divider borderColor={`brand.blue.900`} />

                            {itemsSecondary.map((item, index) =>
                                <Text
                                    fontWeight={'600'}
                                    fontSize='10px'
                                    key={`${index}-${item}`}
                                >
                                    {item}
                                </Text>
                            )}
                        </Stack>
                    </Box>
                    <SimpleGrid
                        columns={2}
                    >
                        <Box>
                            <Box
                                my={`5mm`}
                                fontSize={'10px'}
                            >
                                <Italic>
                                    Issue date:{` `}
                                </Italic>
                                <Strong ml={`.5mm`}>
                                    {` `}
                                    {now.format(`DD MMMM YYYY`)}
                                </Strong>

                            </Box>
                            <Box
                                w={`25mm`}
                                h={`25mm`}
                                bgImage={`url(${stampPurple})`}
                                bgSize={`2.5cm 2.5cm`}
                                bgRepeat={`no-repeat`}
                            // display={bg ? `block` : `none`}
                            // bgPos={`10mm 10mm`}
                            />
                        </Box>


                        <Flex
                            justify={'flex-end'}
                            flexGrow={0}
                        >
                            <Box>
                                <DiplomadQrCode w='20mm' bg={bg} />
                            </Box>
                        </Flex>

                    </SimpleGrid>

                </Box>
            </Flex>
        </>
    )
}