import { Box, Center, HStack, Flex, Grid, Text, Image } from "@chakra-ui/react";
import * as React from "react";
import Debug from "../../Forms/Debug";

import { TranscriptBody } from "./Body";
import { TranscriptHeader } from "./Header";
import diplomaBgImage from "../../Images/transcript-background.jpg";
import { TranscriptFooter } from "./Footer";
import bgGlobe from "../../Images/patterns/globes-white.svg";
// import Logo from '../../Images/logo/primary-blue.svg'
import Logo from "../../Images/logo/vertical-blue-extended.svg";
import stampWhite from "../../Images/stamps/stamps-white.png";
import { config } from "../../Utils/Config";

export const TranscriptLayout = ({ data, promoNotes, bg, page, lastPage }) => {
  const bgParams = () =>
    bg && bg === true
      ? {
          // "bgImage": `url(${diplomaBgImage})`,
          // "bgSize": '297mm 209.5mm'
        }
      : {};

  const pageStyle = `
        @page {
            size: a4 portrait;
            margin: 0cm !important;
        }
        html, body {
            width: 209mm;
            height: 296.5mm;
        }
    `;

  const isMultipage = () => (page && page > 0 ? true : false);
  const isLastPage = () => (page === lastPage ? true : false);

  const {
    school: { _id: schoolId, logoFileVertical, displaySchoolLogoOnDocuments },
  } = data;

  const LogoUrl = !displaySchoolLogoOnDocuments
    ? Logo
    : `${config.apiUrl}/document/${schoolId}/${logoFileVertical}`;

  return (
    <>
      <style>{pageStyle}</style>
      <Box
        className="main-page"
        h="296.5mm"
        w="209.5mm"
        overflow={`hidden`}
        // p={12}

        display="flex"
        flexFlow={"column"}
        bgColor={bg ? "brand.violet.100" : ""}
        bgImage={bg ? `url(${bgGlobe})` : ""}
        bgRepeat={`repeat`}
        bgSize={`20mm 20mm`}
        {...bgParams()}
      >
        <Grid templateColumns={`1fr 16mm`}>
          <Flex
            visibility={bg ? "initial" : "hidden"}
            h="16mm"
            bgColor="brand.blue.900"
            color="white"
            alignItems={`center`}
            px={`8mm`}
            textTransform="uppercase"
            fontFamily={"Satoshi"}
            fontWeight={800}
            letterSpacing={`2.2mm`}
          >
            Transcript of records
          </Flex>
          <Box
            bgColor={"brand.violet.100"}
            visibility={bg ? "initial" : "hidden"}
          />

          <Box h="281mm">
            <TranscriptHeader data={data} />
            <Grid
              templateColumns={`
                                20mm 1fr
                            `}
              h={`225mm`}
            >
              <Flex alignItems={"flex-end"} justifyContent="center">
                <Flex>
                  <Image
                    alt={`Transcript emiiter logo`}
                    src={LogoUrl}
                    maxW="auto"
                    h={`260px`}
                    // h={`60px`}
                    // transform='rotate(-90deg)'
                  />
                </Flex>
              </Flex>
              <Flex
                id="printlogo"
                minH="215mm"
                flexFlow={`column`}
                px={`10mm`}
                py={`5mm`}
                // border='dashed 1px'
                cod
                color={`brand.blue.900`}
                bgImage={bg ? `url(${stampWhite})` : ``}
                bgPos={`center center`}
                bgSize={`12cm 12cm`}
                bgRepeat={`no-repeat`}
              >
                <TranscriptBody
                  data={data}
                  promoNotes={promoNotes}
                  pageNumber={page}
                  lastPage={lastPage}
                />
                {!isMultipage() || (isMultipage() && isLastPage()) ? (
                  <TranscriptFooter
                    data={data}
                    promoNotes={promoNotes}
                    bg={bg}
                  />
                ) : null}
              </Flex>
            </Grid>
            <Text
              fontFamily={`Georgia`}
              fontSize={`10px`}
              fontStyle={`italic`}
              pl={`8mm`}
              pt={`4mm`}
              mb={`10mm`}
            >
              A transcript requires a seal. Explanatory key and Authenticity
              statement appear on reverse side.
            </Text>
          </Box>
          <Box
            bgColor="brand.violet.400"
            visibility={bg ? "initial" : "hidden"}
          >
            <Flex justifyContent={"center"}>
              <Flex
                textTransform="uppercase"
                fontFamily={"Satoshi"}
                fontWeight={600}
                letterSpacing={`2.2mm`}
                color="white"
                px={`4mm`}
                sx={{
                  writingMode: "vertical-rl",
                }}
                display={`none`}
              >
                This is a copy
              </Flex>
            </Flex>
          </Box>
        </Grid>
      </Box>
    </>
  );
};
