import { Box, Button, ButtonGroup, Flex, Grid, Link as HtmlLink, Heading, HStack, SimpleGrid, Text } from '@chakra-ui/react'
import * as React from 'react'
import { DiplomaHeader } from './Header'
// import { DiplomadQrCode } from './QrCode'

import diplomaBgImage from '../../Images/transcript-background.jpg'
import { Link, useParams } from 'react-router-dom'
import { TranscriptHeader } from '../Transcript/Header'
import { Loader } from '../../UI/Loader'
import { DiplomaLayout } from './Layout'
import axios from 'axios'
import { config } from '../../Utils/Config'
import { DiplomaBody } from './Body'
import { DiplomaFooter } from './Footer'
import { BgSwitcher } from './BgSwitcher'
import { GrDocumentPdf } from 'react-icons/gr'
import { BiLeftArrow, BiLeftArrowAlt } from 'react-icons/bi'

export const ToolsButtons = ({
    handleChangeBg,
    diplomaTitle,
    inscriptionId,
    bg,
    exportUrl
}) => (
    <Flex
        borderBottom={'solid 1px'}
        borderBottomColor='gray.50'
        p={10}
        className='hide-print'

        justifyContent={`space-between`}
    >
        <ButtonGroup
            size='xs'
            justifyContent={'flex-end'}
        >
            <Button
                leftIcon={<BiLeftArrowAlt />}
                as={Link}
                to={`/admin/inscription`}
            >
                Back
            </Button>
            <BgSwitcher
                bg={bg}
                handleChangeBg={handleChangeBg}
            />
            <Button
                as='a'
                // href={`${config.apiUrl}/inscription/diploma/${inscriptionId}/${bg}/${diplomaTitle}`}
                href={`${exportUrl}`}
                target='_blank'
                leftIcon={<GrDocumentPdf />}
            >
                Export PDF
            </Button>

        </ButtonGroup>
        <Box>
            <Text
                fontSize={`xs`}
            >
                The transcript notice is reachable by accessing{` `}
                <HtmlLink
                    as={`a`}

                    target={`_blank`}
                    textDecor={`underline`}
                    href={`/EABHES_Transcript_notice.pdf`}
                >
                    this link
                </HtmlLink>.
            </Text>
        </Box>

    </Flex>

)